import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useExtensions() {
  const t = inject("t");
  const store = useStore();
  const extension = ref({
    id: "",
    extension: "",
    is_active: 1,
    password: "",
    domain: "",
  });
  const loading = ref(true);
  const extensions = ref([]);
  const presentations = ref([]);
  const freeExtensions = ref([]);
  const freeAgentExtensions = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND EXTENSIONS
  const findExtensions = async () => {
    try {
      const response = await api.get("/admin/xpbx/extension");

      if (response?.data) extensions.value = response.data;
    } catch (error) {
      console.log("findExtension -> error", error);
    } finally {
      loading.value = false;
    }
  };

  //   FIND EXTENSION
  const findExtension = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/extension/${id}`);

      if (response?.data?.length) {
        extension.value = response.data[0];
      }
    } catch (error) {}
  };

  //   FIND Presentation number
  const findPresentations = async () => {
    try {
      const response = await api.get(`/admin/xpbx/number`);

      if (response?.data) presentations.value = response.data;
    } catch (error) {}
  };

  //   FIND FREE EXTENSION
  const findFreeExtension = async () => {
    try {
      const response = await api.get(`/admin/xpbx/free/extension`);

      if (response?.data?.length) {
        freeExtensions.value = response.data;
      }
    } catch (error) {}
  };

  //   FIND AGENT FREE EXTENSION
  const findAgentFreeExtensions = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/free/extension/${id}`);

      if (response?.data?.length) {
        freeAgentExtensions.value = response.data;
      }
    } catch (error) {}
  };

  //   CREATE EXTENSION
  const createExtension = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/extension`, data);

      if (response?.data) {
        extensions.value = [response.data, ...extensions.value];
        n("notify.xpbx.create_extension", "success");
      } else {
        n("notify.xpbx.error_create_extension");
      }
    } catch (error) {
      n("notify.xpbx.error_create_extension");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE EXTENSION
  const updateExtension = async (data, id) => {
    const formData = {
      extension: data.extension || null,
      is_active: data.is_active,
      password: data.password || "",
      description: data.description || "",
      status: data.status || 1,
    };

    if (data.presentation_number === "Select number") {
      formData.presentation_number = null;
    } else {
      formData.presentation_number = data.presentation_number;
    }

    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(`/admin/xpbx/extension/${id}`, formData);

      if (response?.data?.id) {
        const index = extensions.value.findIndex((i) => i.id === id);
        extensions.value[index] = response.data;
        n("notify.xpbx.edit_extension", "success");
      } else {
        n("notify.xpbx.error_edit_extension");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_extension");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE EXTENSION
  const deleteExtension = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(`/admin/xpbx/extension/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_extension", "success");

        extensions.value = extensions.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_extension");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_extension");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    loading,
    extension,
    extensions,
    presentations,
    freeExtensions,
    freeAgentExtensions,
    findExtension,
    findExtensions,
    updateExtension,
    createExtension,
    deleteExtension,
    findFreeExtension,
    findPresentations,
    findAgentFreeExtensions,
  };
}
