/* eslint-disable */
const routes = [
  {
    path: "/xpbx",
    name: "xpbx",
    component: () => import("@/modules/xpbx/pages/dashboard"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
    children: [],
  },
  {
    path: "/xpbx/reports",
    name: "xpbx-reports",
    component: () => import("@/modules/xpbx/pages/reports"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
  {
    path: "/xpbx/settings",
    name: "xpbx-settings",
    component: () => import("@/modules/xpbx/pages/settings"),
    children: [
      {
        path: "domains",
        name: "Domains",
        component: () =>
          import("@/modules/xpbx/pages/settings/domains/Domains.vue"),
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import("@/modules/xpbx/pages/settings/users/Users.vue"),
      },
      {
        path: "timezones",
        name: "Timezones",
        component: () =>
          import("@/modules/xpbx/pages/settings/timezones/Timezones.vue"),
      },
      {
        path: "sound-files",
        name: "SoundFiles",
        component: () =>
          import("@/modules/xpbx/pages/settings/sound-files/SoundFiles.vue"),
      },
      {
        path: "carriers",
        name: "Carriers",
        component: () =>
          import("@/modules/xpbx/pages/settings/carriers/Carriers.vue"),
      },
      {
        path: "routing",
        name: "Routing",
        component: () =>
          import("@/modules/xpbx/pages/settings/routing/Routing.vue"),
      },
      {
        path: "destinations",
        name: "Destinations",
        component: () =>
          import("@/modules/xpbx/pages/settings/destinations/Destinations.vue"),
      },
      {
        path: "carrier/:id",
        name: "CarrierDetail",
        component: () =>
          import(
            "@/modules/xpbx/pages/settings/carrier-detail/CarrierDetail.vue"
          ),
      },
      {
        path: "carrier/:id/trunk/:trunkId",
        name: "TrunkRules",
        component: () =>
          import("@/modules/xpbx/pages/settings/carrier-detail/TrunkRules.vue"),
      },
      {
        path: "did-numbers",
        name: "DidNumbers",
        component: () =>
          import("@/modules/xpbx/pages/settings/did-numbers/DidNumbers.vue"),
      },
      {
        path: "extensions",
        name: "Extensions",
        component: () =>
          import("@/modules/xpbx/pages/settings/extensions/Extensions.vue"),
      },
      {
        path: "flows",
        name: "Flows",
        component: () =>
          import("@/modules/xpbx/pages/settings/flows/Flows.vue"),
      },
      {
        path: "flow/:id",
        name: "FlowDetail",
        component: () =>
          import("@/modules/xpbx/pages/settings/flow-detail/FlowDetail.vue"),
      },
      {
        path: "flow/:id/version/:versionId",
        name: "FlowVersionDetail",
        component: () =>
          import(
            "@/modules/xpbx/pages/settings/flow-version-detail/FlowVersionDetail.vue"
          ),
      },
      {
        path: "ring-groups",
        name: "RingGroups",
        component: () =>
          import("@/modules/xpbx/pages/settings/ring-groups/RingGroups.vue"),
      },
      {
        path: "ring-group/:id",
        name: "RingGroupDetail",
        component: () =>
          import(
            "@/modules/xpbx/pages/settings/ring-group-detail/RingGroupDetail.vue"
          ),
      },
      {
        path: "queues",
        name: "Queue",
        component: () =>
          import("@/modules/xpbx/pages/settings/queue/Queues.vue"),
      },
      {
        path: "queue/:id",
        name: "QueueDetail",
        component: () =>
          import("@/modules/xpbx/pages/settings/queue-detail/QueueDetail.vue"),
      },
      {
        path: "voicemail",
        name: "VoiceMail",
        component: () =>
          import("@/modules/xpbx/pages/settings/voicemail/VoiceMail.vue"),
      },
      {
        path: "settings",
        name: "SettingsPage",
        component: () => import("@/modules/xpbx/pages/settings/settings"),
      },
      {
        path: "blacklist",
        name: "Blacklist",
        component: () =>
          import("@/modules/xpbx/pages/settings/blacklist/Blacklist.vue"),
      },
      // {
      //   path: "queues",
      //   name: "Queues",
      //   component: () => import("@/modules/xpbx/pages/settings/queues"),
      // },
      {
        path: "create-domain",
        name: "CreateDomain",
        component: () => import("@/modules/xpbx/pages/settings/create-domain"),
      },
      {
        path: "templates",
        name: "Templates",
        component: () =>
          import("@/modules/xpbx/pages/settings/templates/Templates.vue"),
      },
      {
        path: "contacts",
        name: "SettingsContact",
        component: () =>
          import("@/modules/xpbx/pages/settings/contacts/Contacts.vue"),
      },
      {
        path: "senders",
        name: "ViberSenders",
        component: () => import("@/modules/xpbx/pages/settings/viber-senders/Senders.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
];
export default routes;
