import { ref, inject } from "vue";
import rootApi from "@/services/rootApi";
import userApi from "@/services/userApi";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default function useUsers() {
  const t = inject("t");
  const user = ref({
    id: "",
    description: "",
    username: "",
    password: "",
    extension: "",
    timezone: "",
    domain: "",
    is_active: false,
  });
  const store = useStore();
  const route = useRoute();
  const users = ref([]);
  const loading = ref(true);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  const getAPI = () => {
    const token = localStorage.getItem("token");

    const pageName = route?.name;

    if (
      pageName &&
      (pageName === "Users" ||
        pageName === "QueueDetail" ||
        pageName === "RingGroupDetail")
    )
      return userApi;

    if (!token) return userApi;

    return token ? rootApi : userApi;
  };

  //   FIND USERS
  const findUsers = async () => {
    const api = getAPI();
    try {
      const response = await api.get("/admin/xpbx/user");

      if (response?.data) {
        const mappedUsers = response.data.map((user) => {
          return {
            ...user,
            updated_at: new Date(user.updated_at),
            created_at: new Date(user.created_at),
          };
        });
        users.value = mappedUsers;
      }

      return response?.data ? response.data : [];
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND USER
  const findUser = async (id) => {
    const api = getAPI();
    try {
      const response = await api.get(`/admin/xpbx/user/${id}`);

      if (response?.data?.length) {
        const user = response.data[0];
        const responseUser = {
          id: user.id,
          description: user.description || "",
          username: user.username || "",
          password: user.password || "",
          extension: user.extension || "",
          timezone: user.timezone || "",
          domain: user.domain || "",
          is_active: user.is_active === 1 ? true : false,
        };

        user.value = { ...responseUser };

        return responseUser;
      }
    } catch (error) {}
  };

  //   UPDATE USER
  const updateUser = async (user, id, extensionId = null) => {
    const api = getAPI();
    let timezone = user.timezone || null;
    let extension = extensionId;
    if (timezone === "Select timezone") timezone = null;
    if (extension === "Select extension") extension = null;

    const formData = {
      extension_id: extension,
      extension: extension,
      is_active: user.is_active === true || user.is_active === 1 ? 1 : 0,
      timezone,
    };

    console.log(formData);

    if (user?.password) formData.password = user.password;
    if (user?.username) formData.username = user.username;
    if (user?.description) formData.description = user.description;

    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);
      const response = await api.put(`/admin/xpbx/user/${id}`, formData);

      if (response?.data?.id) {
        const index = users.value.findIndex((i) => i.id === id);
        users.value[index] = response.data;
        n("xpbx.settings.users.notification.update_user", "success");
      } else {
        n("xpbx.settings.users.notification.error_update_user");
      }
    } catch (error) {
      n("xpbx.settings.users.notification.error_update_user");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   CREATE USER
  const createUser = async (user, extensionId) => {
    const api = getAPI();
    const formData = {
      description: user?.description || null,
      username: user?.username || null,
      password: user?.password || "",
      is_active: user?.is_active === true ? 1 : 0,
    };

    if (extensionId) formData.extension_id = extensionId;
    if (user?.timezone) formData.timezone = user?.timezone;

    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);
      const response = await api.post(`/admin/xpbx/user`, formData);

      if (response?.data) {
        users.value = [response.data, ...users.value];
        n("xpbx.settings.users.notification.create_user", "success");
      } else {
        n("xpbx.settings.users.notification.error_create_user");
      }
    } catch (error) {
      n("xpbx.settings.users.notification.error_create_user");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE USER
  const deleteUser = async (id) => {
    const api = getAPI();
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);
      const response = await api.delete(`/admin/xpbx/user/${id}`);
      if (response.status === 200) {
        users.value = users.value.filter((i) => i.id !== id);
        n("xpbx.settings.users.notification.delete_user", "success");
      } else {
        n("xpbx.settings.users.notification.error_delete_user");
      }

      return response;
    } catch (error) {
      n("xpbx.settings.users.notification.error_delete_user");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE USERS
  const deleteUsers = async (selectedUsers) => {
    const api = getAPI();
    for (const id of selectedUsers) {
      try {
        const response = await api.delete(`/admin/xpbx/user/${id}`);

        if (response.status === 200) {
          users.value = users.value.filter((i) => i.id !== id);
        }
      } catch (error) {
        n("xpbx.settings.users.notification.error_delete_user");
      }
    }

    n("xpbx.settings.users.notification.delete_user", "success");
  };

  // USERS SEARCH
  const searchUsers = async (filters) => {
    const api = getAPI();
    try {
      const response = await api.post(`/admin/xpbx/user/search`, filters);

      if (response?.data?.length) users.value = response.data;
    } catch (error) {
      n("xpbx.settings.users.notification.error_delete_user");
    }
  };

  const userImpersonate = async (id) => {
    const api = getAPI();
    try {
      const response = await api.get(`/admin/user-login/${id}`);

      if (response?.data?.access_token) {
        localStorage.setItem("userToken", response.data.refresh_token);
        sessionStorage.setItem("userToken", response.data.access_token);
        n("xpbx.settings.users.notification.impersonate_user", "success");
        return true;
      } else {
        n("xpbx.settings.users.notification.error_impersonate_user");
        return false;
      }
    } catch (error) {
      n("xpbx.settings.users.notification.error_impersonate_user");
      return false;
    }
  };

  const logout = async () => {
    sessionStorage.removeItem("userToken");
    localStorage.removeItem("userToken");
    n("xpbx.settings.users.notification.logout_impersonate_user", "success");
  };

  return {
    user,
    users,
    loading,
    logout,
    findUser,
    findUsers,
    createUser,
    updateUser,
    deleteUser,
    deleteUsers,
    searchUsers,
    userImpersonate,
  };
}
