<template>
  <div class="relative">
    <label
      :for="name"
      class="text-xl font-inter font-bold"
      v-show="!!headerLabel"
    >
      {{ headerLabel }}
      <span class="text-red-700 font-bold">*</span>
    </label>
    <div class="text-xl font-inter">
      <div class="form-control">
        <div class="dropdown-with-arrow">
          <input
            type="text"
            name="search"
            class="block border-0 border-b-half w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100 border-light-green placeholder-gray-500 focus:border-green-700"
            :placeholder="placeholder"
            autocomplete="off"
            @click.stop="open"
            @focus="open"
            @keyup="navigate"
            @keyup.stop.esc="close"
            @keyup.stop.enter="updateAndClose(active)"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
          />
        </div>
      </div>
    </div>

    <dropdown
      :id="dropdownId"
      v-if="isActiveDropdown(dropdownId)"
      classes="top-4 left-0.5"
      style="width: 100%; max-height: 11rem; overflow-y: auto"
    >
      <template v-if="filteredOptions.length">
        <dropdown-item
          classes="dropdown-item bg-white hover:bg-gray-200"
          :class="{ 'active-dropdown': index == 0 }"
          style="padding-top: 5px !important; padding-bottom: 5px !important"
          @click.prevent="updateAndClose(option)"
          v-for="(option, index) in filteredOptions"
          :key="option"
        >
          {{ option }}
        </dropdown-item>
      </template>
      <dropdown-item v-else>{{
        $t('label.app.common.no_match')
      }}</dropdown-item>
    </dropdown>
    <label
      v-show="!!footerLabel"
      for="name"
      class="text-xs"
      :class="!!errors ? 'p-error' : 'text-gray-500'"
    >
      {{ !!errors ? errors[0] : footerLabel }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'selectWithSearch',
  inheritAttrs: false
}
</script>

<script setup>
import { useAttrs, computed, ref, onMounted } from 'vue'
import useActiveToggler from '@/composables/useActiveToggler'
import lodash from 'lodash'

const props = defineProps({
  options: {
    type: [Object, Array],
    required: true
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: null
  },
  userLanguages: {
    type: [Array, Object],
    required: false,
    default: () => []
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  footerLabel: {
    required: false,
    type: String,
    default: ''
  },
  headerLabel: {
    required: false,
    type: String,
    default: ''
  },
  errors: {
    required: false,
    default: () => [],
    type: [Array, Object]
  },
  name: {
    required: false,
    default: '',
    type: String
  }
})

const emit = defineEmits(['update:modelValue'])

const active = ref('')

const attrs = useAttrs()

const dropdownId = computed(() => attrs.id || 'customSelectDropdown')
const filteredOptions = computed(() => filter())

const filter = () => {
  return Object.entries(props.options)
    .filter(([code, lang]) => callback({ code, name: lang.name }))
    .map(([, lang]) => lang.name)
}

const callback = ({ code, name }) => {
  const isNotLangInclude =
    !props.userLanguages.length || !props.userLanguages.includes(code)
  if (props.modelValue) {
    console.log('modelValue', props.modelValue);
    
    return (
      name.toLowerCase().indexOf(props?.modelValue.toLowerCase()) != -1 &&
      isNotLangInclude
    )
  }
  return isNotLangInclude
}

const updateAndClose = value => {
  if (value == '' || value == null || value == undefined) return
  emit('update:modelValue', value)
  toggleDropdown(dropdownId.value)
}

const close = () => {
  if (isActiveDropdown(dropdownId.value)) toggleDropdown(dropdownId.value)
}

const open = () => {
  if (isActiveDropdown(dropdownId.value) == false)
    toggleDropdown(dropdownId.value)
}

const navigate = e => {
  if (e.keyCode == 40 || e.keyCode == 38) {
    open()

    const items = document.querySelectorAll('.dropdown-item')
    const item = Object.entries(items).find(([, item]) => {
      return [...item.classList].includes('active-dropdown')
    })

    if (!lodash.isEmpty(item)) {
      const elem = item[1]
      const navigateToElement =
        e.keyCode == 40 ? elem.nextElementSibling : elem.previousElementSibling
      elem.classList.remove('active-dropdown')

      if (navigateToElement == null) {
        const resetIndex = e.keyCode == 40 ? 0 : items.length - 1
        items[resetIndex].classList.add('active-dropdown')
        active.value = items[resetIndex].innerText
      } else {
        navigateToElement.classList.add('active-dropdown')
        active.value = navigateToElement.innerText
      }
    }
  }
}

const { toggleDropdown, isActiveDropdown } = useActiveToggler()
</script>

<style lang="scss" scoped>
.dropdown::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: -12px;
  right: -1px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.active-dropdown {
  background: rgba(229, 231, 235, 1);
}
</style>
