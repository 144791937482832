<template>
  <div class="field flex flex-col justify-start">
    <label class="text-left" :for="id">{{ label }}</label>
    <Textarea
      :id="id"
      :value="modelValue"
      rows="3"
      cols="20"
      :class="{ 'p-invalid': !!error }"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <small class="p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <div class="mb-4" v-else></div>
</template>

<script>
import Textarea from "primevue/textarea";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "FormTextArea",

  components: {
    Textarea,
    QueueHint,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    modelValue: {
      required: true,
      type: [String, Object, Number],
    },

    label: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },
  },
};
</script>