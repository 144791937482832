import authenticate from "@/composables/auth/authenticate";
import validator from "@/composables/auth/validator";
import axios from "axios";
import jwt_decode from "jwt-decode";
import router from "@/router";
import { checkGuestPages } from "../middleware";

const { logout } = authenticate();
const { fillErrors } = validator();
const baseURL = process.env.API_URL || "http://127.0.0.1:8000/";

// const instance = axios.create();
// instance.defaults.timeout = 1000;

class api {
  /**
   * Init service logic
   *
   * @returns void
   */
  // constructor() {
  //   // axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  //   // setErrorInterceptor();
  //   // setHeadersInterceptor();
  // }

  async get(url, config = {}) {
    try {
      const queryParams = new URLSearchParams(config);
      const headers = await this.setDefaultHeaders({}, url);
      return await axios.get(
        baseURL + url,
        { headers },
        { params: queryParams }
      );
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async getData(url, params = {}) {
    const headers = await this.setDefaultHeaders();

    let config = {
      headers,
      params,
    };

    try {
      return await axios.get(baseURL + url, config);
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async post(url, data, config = {}) {
    try {
      const headers = await this.setDefaultHeaders(config);
      return await axios.post(baseURL + url, data, { headers });
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async register(url, data) {
    try {
      return await axios.post(baseURL + url, data, {
        "Content-Type": "application/json",
      });
    } catch (e) {
      return e;
    }
  }

  async put(url, data, config = {}) {
    try {
      const headers = await this.setDefaultHeaders(config);
      return await axios.put(baseURL + url, data, { headers });
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async patch(url, data, config = {}) {
    try {
      const headers = await this.setDefaultHeaders(config);
      return await axios.patch(baseURL + url, data, { headers });
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async delete(url, config = {}) {
    try {
      const headers = await this.setDefaultHeaders(config);
      return await axios.delete(baseURL + url, { headers });
    } catch (e) {
      this.errorHandling(e);
    }
  }

  errorHandling(error) {
    if ([401, 403, 419].includes(error.status)) {
      logout();
      return;
    } else {
      if (error?.response?.data?.errors) {
        fillErrors(error.response.data.errors);
      }
    }
    return Promise.reject(error);
  }

  async refreshTokens() {
    try {
      const token = localStorage.getItem(this.getTokenName());
      // reset access token
      const headers = { Authorization: "Bearer " + token };
      return await axios.get(baseURL + "/admin/oauth", { headers });
    } catch (e) {
      this.errorHandling(e);
    }
  }

  async setDefaultRefisterHeaders(config = {}) {
    const contentType = "application/json";
    return (headers = {
      ...config,
      "Content-Type": contentType,
    });
  }

  getTokenName(url) {
    if (url && url === "/admin/me") return "token";
    const userToken = localStorage.getItem("userToken");

    return userToken ? "userToken" : "token";
  }

  async setDefaultHeaders(config = {}, url) {
    const contentType = "application/json";

    let atoken = sessionStorage.getItem(this.getTokenName(url));
    const rtoken = localStorage.getItem(this.getTokenName(url));

    if (atoken == null && rtoken != null) {
      const response = await this.refreshTokens(url);
      if (
        response &&
        response.data.access_token &&
        response.data.refresh_token
      ) {
        localStorage.setItem(
          this.getTokenName(url),
          response.data.refresh_token
        );
        sessionStorage.setItem(
          this.getTokenName(url),
          response.data.access_token
        );
        atoken = response.data.access_token;
      }
    } else if (atoken == null && rtoken == null) {
      const guestPage = checkGuestPages(window.location.pathname);

      if (!guestPage) router.push("login");
      return;
    }

    if (atoken != null && rtoken != null) {
      const jwt = jwt_decode(atoken);
      const tokenExpire = jwt.exp * 1000;
      const nowTime = Date.now();
      if ((tokenExpire - nowTime) / 1000 <= 10) {
        // console.log("Token is expired: " + (tokenExpire - nowTime) / 1000);
        const response = await this.refreshTokens();
        if (
          response &&
          response.data.access_token &&
          response.data.refresh_token
        ) {
          localStorage.setItem(
            this.getTokenName(url),
            response.data.refresh_token
          );
          sessionStorage.setItem(
            this.getTokenName(url),
            response.data.access_token
          );
          atoken = response.data.access_token;
        }
      }

      if (url && url.includes("/admin/me")) {
        console.log("setDefaultHeaders", url, this.getTokenName(url));
        console.log("atoken", url, atoken);
        console.log("rtoken", url, rtoken);
      }

      const headers = {
        ...config,
        "Content-Type": contentType,
        Authorization: `Bearer ${sessionStorage.getItem(
          this.getTokenName(url)
        )}`,
      };
      return headers;
    } else {
      return {
        ...config,
        "Content-Type": contentType,
      };
    }
  }
}

export default new api();
