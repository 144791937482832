<template>
  <div class="flex items-center gap-2">
    <i
      class="fa-light fa-thumbs-up edit-icon"
      :class="{ inactive: isActive === 0 }"
      @click="$emit('setActive', data)"
      v-if="showIsActive"
      v-tooltip.top="activeText"
    ></i>

    <i
      v-if="showEdit"
      @click="$emit('edit', data)"
      v-tooltip.top="editTooltip"
      class="fa-light fa-pen-to-square edit-icon"
    ></i>

    <router-link :to="link" v-if="link">
      <i
        class="fa-light edit-icon"
        :class="[link && !showEdit ? 'fa-pen-to-square' : 'fa-money-check-pen']"
        v-tooltip.top="linkTooltip"
      ></i
    ></router-link>

    <i
      v-if="showClone"
      v-tooltip.top="$t('xpbx.settings.tooltips.clone_ivr_version')"
      class="fa-light fa-clone edit-icon clone-icon"
      @click="$emit('clone', data?.id)"
    ></i>

    <i
      v-if="showDelete"
      @click="$emit('delete', data)"
      v-tooltip.top="deleteTooltip"
      class="fa-light fa-trash edit-icon"
    ></i>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import Tooltip from "primevue/tooltip";

export default {
  name: "TableActions",

  directives: {
    tooltip: Tooltip,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Number,
      required: false,
      default: 0,
    },

    showIsActive: {
      type: Boolean,
      required: false,
      default: true,
    },

    link: {
      type: String,
      required: false,
      default: "",
    },

    showDelete: {
      type: Boolean,
      required: false,
      default: true,
    },

    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },

    showClone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const t = inject("t");

    const activeText = ref(t("xpbx.settings.tooltips.is_active"));
    const editTooltip = ref(t("xpbx.settings.tooltips.edit"));
    const deleteTooltip = ref(t("xpbx.settings.tooltips.delete"));
    const linkTooltip = ref(t("xpbx.settings.tooltips.link"));

    return {
      activeText,
      editTooltip,
      deleteTooltip,
      linkTooltip,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  color: #10b981;
  font-size: 1.1rem;
}

.clone-icon {
  font-size: 1.1rem;
}

.inactive {
  transform: rotate(180deg);
}
</style>