import { createRouter, createWebHistory } from "vue-router";
import widgetRoutes from "@/modules/widget/router/index.js";
import profileRoutes from "@/modules/profile/router/index.js";
import virtualNumRoutes from "@/modules/virtualnum/router/index.js";
import xpbxRoutes from "@/modules/xpbx/router/index.js";
import billingRoutes from "@/modules/billing/router.js";
import xadminRoutes from "@/modules/xadmin/router/index.js";

import validator from "@/composables/auth/validator";
import authenticate from "@/composables/auth/authenticate";
import i18nSetup from "@/services/i18nSetup";

const { isLoggedIn, getAuth: auth } = authenticate();
const { clearErrors } = validator();
const { loadLanguageAsync } = i18nSetup();

let routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/pages/login"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/pages/home"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "home" */ "@/pages/about"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/xadmin",
    name: "xadmin",
    component: () => import(/* webpackChunkName: "home" */ "@/pages/xadmin"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/configuration",
    name: "configuration",
    component: () =>
      import(/* webpackChunkName: "configuration" */ "@/pages/configuration"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/settings"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/pages/register"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/pages/forgot-password"
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/profile/unsubscribe/success",
    name: "UnsuscribeSuccess",
    component: () =>
      import(/* webpackChunkName: "ThankYou" */ "@/pages/thank-you"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/payments/thank-you",
    name: "PaymentThankYou",
    component: () =>
      import(/* webpackChunkName: "ThankYou" */ "@/pages/thank-you"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/subscription/thank-you",
    name: "ThankYou",
    component: () =>
      import(/* webpackChunkName: "ThankYou" */ "@/pages/thank-you"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password/:token",
    name: "resetPassword",
    props: true,
    component: () =>
      import(/* webpackChunkName: "resetPassword" */ "@/pages/ResetPassword"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/pages/not-found"),
    meta: {
      requiresAuth: false,
    },
  },
];

routes = [
  ...routes,
  ...widgetRoutes,
  ...profileRoutes,
  ...virtualNumRoutes,
  ...xpbxRoutes,
  ...xadminRoutes,
  ...billingRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const lang = to.meta.lang;
  clearErrors();
  if (to.meta.requiresAuth && !isLoggedIn()) {
    next({
      name: "login",
    });
  } else if (
    isLoggedIn() &&
    to.meta.verified &&
    !!auth.value.email_verified_at == false
  ) {
    next({
      name: "BaseProfile",
    });
  } else next();
});

export default router;
