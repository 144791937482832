
<template>
  <section class="w-full h-full">
    <PageHeader
      :heading="$t('xpbx.settings.users.heading.users')"
      :showButton="false"
    />
    <div class="card relative table-wrapper contact-wrapper">
      <DataTable
        ref="dt"
        :value="mappedUsers"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        removableSort
        sortMode="multiple"
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        filterDisplay="menu"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        v-if="!loading"
        :globalFilterFields="['username', 'description']"
      >
        <template #empty>
          {{ $t("xpbx.settings.fields.empty_table") }}</template
        >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                class="mr-2 add-record-button"
                @click="openNew"
              />
            </div>
            <div class="flex gap-2 items-center">
              <InputText
                class="search-input"
                v-model="filters['global'].value"
                :placeholder="$t('xpbx.placeholders.search')"
              />
              <Export
                :dt="dt"
                :tableData="mappedExportableUsers"
                :columns="userHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          field="username"
          :header="$t('xpbx.settings.users.fields.user')"
          sortable
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.username }}
            <div>
              <TableActions
                :data="data"
                :id="data.id"
                :isActive="data.is_active"
                @edit="editHandle"
                @setActive="setActive"
                @delete="deleteSelectedRecord"
              />
            </div>
          </template>
        </Column>
        <Column
          field="description"
          :header="$t('xpbx.settings.users.fields.description')"
          sortable
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.description }}
          </template>
        </Column>
        <Column
          field="extension.extension"
          sortable
          v-if="!showLogin"
          :header="$t('xpbx.settings.users.fields.extension')"
          style="width: 15%"
        >
          <template #body="slotProps">
            {{ slotProps?.data?.extension?.extension }}
          </template>
        </Column>
        <Column
          field="timezone"
          sortable
          :header="$t('xpbx.settings.users.fields.timezone')"
          style="width: 15%"
        >
          <template #body="{ data }">
            {{ data?.timezone }}
          </template>
        </Column>
        <Column
          field="created_at"
          filterField="created_at"
          dataType="date"
          sortable
          :header="$t('xpbx.settings.users.fields.created_at')"
          style="width: 10%"
        >
          <template #body="{ data }">
            <DateItem :value="data?.created_at" />
          </template>
        </Column>
        <Column
          field="updated_at"
          :header="$t('xpbx.settings.users.fields.updated_at')"
          sortable
          style="width: 10%"
        >
          <template #body="slotProps">
            <DateItem :value="slotProps?.data?.updated_at" />
          </template>
        </Column>
        <Column
          field="id"
          v-if="showLogin"
          :header="$t('xpbx.settings.users.fields.settings')"
          style="width: 10%"
        >
          <template #body="slotProps">
            <div class="flex items-center justify-between">
              <div
                @click.prevent="loginUser(slotProps?.data?.id)"
                class="w-12 h-11 flex items-center flex-col justify-center shadow-md hover:opacity-90 color-base-green rounded-md p-1"
              >
                <i class="fa-solid fa-right-to-bracket"></i>
                <span class="text-sm">{{ $t("xpbx.button.login") }}</span>
              </div>
              <div
                @click.prevent="logoutUser"
                v-if="
                  impersonateUser && impersonateUser === slotProps?.data?.id
                "
                class="w-12 h-11 flex-col flex items-center justify-center shadow-md hover:opacity-90 color-base-red rounded-md"
              >
                <i class="fa-solid fa-arrow-up-left-from-circle"></i>
                <span class="text-sm">{{ $t("xpbx.button.logout") }}</span>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <Dialog
      v-model:visible="deleteUsersDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedUser?.username">{{
          $t("xpbx.settings.users.notification.confirm_delete", {
            delete: `user ${selectedUser?.username}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          text
          @click="deleteUsersDialog = false"
          :label="$t('xpbx.settings.users.buttons.no')"
        />
        <Button
          text
          @click="deleteSelecteUsers"
          :label="$t('xpbx.settings.users.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create user -->
    <Dialog
      v-model:visible="userDialog"
      :style="{ width: '800px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.users.dialogs.edit_user')
          : $t('xpbx.settings.users.dialogs.create_user')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Username -->
      <FormInput
        id="username"
        :error="errors?.username?.[0]"
        v-model:modelValue="user.username"
        :label="$t('xpbx.settings.users.labels.username')"
        :hint="$t('xpbx.settings.users.hints.username')"
      />

      <!-- Description -->
      <FormTextArea
        id="description"
        :error="errors?.description?.[0]"
        v-model:modelValue="user.description"
        :label="$t('xpbx.settings.users.labels.description')"
        :hint="$t('xpbx.settings.users.hints.description')"
      />

      <!-- Password -->
      <FormInput
        id="password"
        type="password"
        :error="errors?.password?.[0]"
        v-model:modelValue="user.password"
        :label="$t('xpbx.settings.users.labels.password')"
        :hint="$t('xpbx.settings.users.hints.password')"
      />

      <!-- Extension -->
      <FormDropdown
        v-if="!showLogin"
        optionLabel="name"
        :options="extensionOptions"
        v-model:modelValue="user.extension"
        :label="$t('xpbx.settings.users.labels.extension')"
        :hint="$t('xpbx.settings.users.hints.extension')"
      />

      <!-- Timezones -->
      <Timezones
        v-model="user.timezone"
        :showTitle="false"
        label="label.app.common.timezone_select"
      />

      <!-- Is active -->
      <InputSwitch
        v-model:modelValue="user.is_active"
        :label="$t('xpbx.settings.users.labels.is_active')"
      />
      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.settings.users.buttons.save')"
          :cancelButtonText="$t('xpbx.settings.users.buttons.cancel')"
          @save="create"
          @cancel="hideDialog"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed, watch, inject } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import DeleteModal from "@/modules/xpbx/components/UI/delete-modal";
import useUsers from "@/modules/xpbx/composables/useUsers";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import useExtensions from "@/modules/xpbx/composables/useExtensions";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import validator from "@/composables/auth/validator";
import { validateUser } from "@/composables/auth/userValidations";
// DataTable
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Timezones from "@/shared/Timezones";
import Tooltip from "primevue/tooltip";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormTextArea from "@/modules/xpbx/components/forms/FormTextArea.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import { userHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "UsersTable",
  components: {
    PageHeader,
    DeleteModal,
    Loader,
    DateItem,
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    Export,
    InputSwitch,
    DialogButtons,
    FormInput,
    FormTextArea,
    FormDropdown,
    Timezones,
    TableActions,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    showLogin: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const t = inject("t");
    const dt = ref();
    const totalRecords = ref(0);
    const store = useStore();
    const isEdit = ref(false);
    const submitted = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const showDeleteModal = ref(false);
    const userDialog = ref(false);
    const deleteProductDialog = ref(false);
    const selectAll = ref(false);
    const showExports = ref(false);
    const deleteUsersDialog = ref(false);
    const selectedRecords = ref([]);
    const selectedUser = ref(null);
    const { errors } = validator();
    const selectText = t("xpbx.settings.dropdown.select_option");
    const timezones = computed(() => store.getters.timezoneOptions);
    const impersonateUser = computed(
      () => store.state.profilestore.impersonateUser
    );
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const { findFreeExtension, freeExtensions, findExtensions, extensions } =
      useExtensions();
    const {
      findUsers,
      deleteUser,
      createUser,
      updateUser,
      userImpersonate,
      loading,
      logout,
      users,
      user,
    } = useUsers();

    const extensionOptions = computed(() => {
      return freeExtensions.value.map((item) => {
        return {
          value: item.extension,
          name: item.extension,
        };
      });
    });

    const mappedUsers = computed(() => {
      return users.value.map((item) => {
        return {
          ...item,
          extension: findExtension(item.extension_id),
        };
      });
    });

    const mappedExportableUsers = computed(() => {
      return mappedUsers.value.map((item) => {
        return {
          ...item,
          extension: item?.extension?.extension || "",
        };
      });
    });

    const findExtension = (value) => {
      const extensionData = extensions.value.find((item) => item.id === value);
      return extensionData ? extensionData : null;
    };

    const setActive = async (data) => {
      const userData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };
      const extensionId = data.extension?.id || null;

      await updateUser(userData, userData.id, extensionId);
    };

    const fectchUsers = async () => {
      if (!props.showLogin) await findExtensions();
      await findUsers();
    };

    const resetUser = () => {
      user.value = {
        username: "",
        description: "",
        password: "",
        extension: {
          value: null,
          name: selectText,
        },
        timezone: "Select timezone",
        is_active: true,
      };
      selectedUser.value = null;
      isEdit.value = false;
    };

    // Data Table Methods
    const openNew = async () => {
      if (!props.showLogin) await findFreeExtension();
      resetUser();
      submitted.value = false;
      userDialog.value = true;
    };

    const hideDialog = () => {
      submitted.value = false;
      userDialog.value = false;
      resetUser();
    };

    const closeDialog = (value) => {
      submitted.value = false;
      userDialog.value = value;
      resetUser();
    };

    const editHandle = async (data) => {
      if (!props.showLogin) await findFreeExtension();

      selectedUser.value = data;
      const existedExtension = freeExtensions.value.find(
        (item) => item.id === data?.extension_id
      );

      const userData = {
        ...selectedUser.value,
        is_active: selectedUser.value.is_active === 1 ? true : false,
      };

      if (existedExtension) {
        freeExtensions.value.push({
          id: existedExtension.id,
          extension: existedExtension.extension,
          description: existedExtension.description,
        });
      }
      userData.extension = {
        value: existedExtension?.extension || null,
        name: existedExtension?.extension || selectText,
      };

      user.value = userData;

      isEdit.value = true;
      userDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedUser.value = data;
      deleteUsersDialog.value = true;
    };

    const deleteSelectedRecords = () => {
      deleteUsersDialog.value = true;
    };

    const create = async () => {
      if (isEdit.value) {
        await update();
      } else {
        await createCustomer();
      }
    };

    const createCustomer = async () => {
      submitted.value = true;
      const isValid = validateUser(user.value);
      const extensionId = freeExtensions.value.find(
        (item) => item.extension === user.value.extension
      )?.id;

      try {
        if (isValid) {
          await createUser(user.value, extensionId);
          isEdit.value = false;
          submitted.value = false;
          userDialog.value = false;
        }
      } catch (error) {}
    };

    const update = async () => {
      const extensionId = freeExtensions.value.find(
        (item) => item.extension === user.value.extension
      )?.id;

      const formData = {
        ...user.value,
        extension: user.value.extension?.value
          ? user.value.extension.value
          : user.value.extension || "",
        timezone: user.value.timezone?.value
          ? user.value.timezone.value
          : user.value.timezone || "",
      };

      try {
        await updateUser(formData, user.value.id, extensionId);
        isEdit.value = false;
        submitted.value = false;
        userDialog.value = false;
      } catch (error) {}
    };

    const deleteSelecteUsers = async () => {
      await deleteUser(selectedUser.value.id);
      deleteUsersDialog.value = false;
    };

    watch(userDialog, (value) => {
      if (!value) resetUser();
    });

    // End Filters sort page methods

    const loginUser = async (id) => {
      const response = await userImpersonate(id);
      store.commit("profilestore/setImpersonate", response);
      if (response === true) {
        store.commit("profilestore/setImpersonateUser", id);
        localStorage.setItem("impersonateUser", id);
      } else {
        store.commit("profilestore/setImpersonateUser", null);
        localStorage.removeItem("impersonateUser");
      }
    };

    const logoutUser = async () => {
      await logout();
      store.commit("profilestore/setImpersonate", false);
      localStorage.removeItem("impersonateUser");
      store.commit("profilestore/setImpersonateUser", null);
    };

    const checkImpersonateUser = () => {
      const userId = localStorage.getItem("impersonateUser");

      if (userId) store.commit("profilestore/setImpersonateUser", userId);
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");

        if (classLength > 0 && selectedUser?.value?.id && !includeButton) {
          selectedUser.value = null;
        }
      });
    };

    onMounted(async () => {
      // checkTableClick();
      checkImpersonateUser();
      await fectchUsers();
      if (!props.showLogin) await findFreeExtension();
    });

    return {
      dt,
      user,
      users,
      totalRecords,
      mappedUsers,
      mappedExportableUsers,
      isEdit,
      loading,
      errors,
      submitted,
      timezones,
      selectAll,
      extensions,
      scrollHeight,
      findExtension,
      showDeleteModal,
      selectedRecords,
      createUser,
      userDialog,
      impersonateUser,
      userImpersonate,
      extensionOptions,
      deleteProductDialog,
      showExports,
      deleteUsersDialog,
      selectedUser,
      filters,
      userHeaders,
      findUsers,
      hideDialog,
      deleteUser,
      fectchUsers,
      create,
      openNew,
      editHandle,
      setActive,
      logout,
      loginUser,
      logoutUser,
      closeDialog,
      deleteSelectedRecord,
      deleteSelectedRecords,
      deleteSelecteUsers,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>