// import api from "@/services/api";

import {
  SET_XADMIN_HEADER,
  SET_XADMIN_QUEUE,
  ADD_XADMIN_QUEUE,
  ADD_XADMIN_QUEUE_OPTION,
  SET_XADMIN_GLOBAL_LOADER,
  UPDATE_XADMIN_QUEUE_OPTION,
  DELETE_XADMIN_QUEUE_OPTION,
  SET_XADMIN_NODES,
  SET_XADMIN_EDGES,
  UPDATE_XADMIN_EDGES,
  UPDATE_XADMIN_NODES,
  UPDATE_XADMIN_NODE,
  DELETE_XADMIN_NODE,
  SET_XADMIN_VERSION,
  SET_XADMIN_ACTIONS,
  SET_XADMIN_SETTINGS,
  SET_XADMIN_DRAGGABLE_NODE,
  RESET_XADMIN_NODES,
  UPDATE_XADMIN_NODE_INPUT,
  UPDATE_XADMIN_NODE_POSITION,
  DELETE_XADMIN_NODE_INPUT,
  UPDATE_XADMIN_SETTINGS_VERSION,
  UPDATE_XADMIN_NODE_TITLE,
  SET_XADMIN_FLOW_POSITION,
  SET_XADMIN_FLOW_FIT_VIEW,
  SET_XADMIN_NODE_MENU_OPTIONS,
  SET_XADMIN_COMMAND,
  SET_XADMIN_DESTINATIONS,
  ADD_XADMIN_DESTINATIONS,
  FILTER_XADMIN_DESTINATIONS,
  DELETE_XADMIN_DESTINATIONS,
  UPDATE_XADMIN_DESTINATIONS,
} from "./types";

export const xadmin = {
  namespaced: true,

  state: {
    header: [],
    nodes: [],
    edges: [],
    flowActions: [],
    destinations: [],
    filteredDestinations: [],
    queue: {},
    zoomData: null,
    version: null,
    initialVersion: null,
    draggableNode: null,
    selectedSettings: null,
    selectedNodeId: null,
    selectedMenuOptions: null,
    nodeTitle: null,
    loading: false,
    fitPosition: false,
    isGlobalLoader: false,
  },

  getters: {
    header: (state) => state.header,
  },

  mutations: {
    [SET_XADMIN_HEADER](state, data) {
      state.header = data;
    },

    [SET_XADMIN_GLOBAL_LOADER](state, data) {
      state.isGlobalLoader = data;
    },

    [SET_XADMIN_QUEUE](state, data) {
      state.queue = data;
    },

    [SET_XADMIN_NODES](state, data) {
      state.nodes = data;
    },

    [SET_XADMIN_EDGES](state, data) {
      state.edges = data;
    },

    [SET_XADMIN_ACTIONS](state, data) {
      state.flowActions = data;
    },

    [SET_XADMIN_VERSION](state, data) {
      state.version = data;
      state.initialVersion = data;
    },

    [SET_XADMIN_NODE_MENU_OPTIONS](state, data) {
      state.selectedMenuOptions = data?.menuOptions;
    },

    [UPDATE_XADMIN_SETTINGS_VERSION](state, data) {
      const selectedNode = state.nodes.find(
        (node) => node.id === state.selectedNodeId
      );

      const newOptions = {
        ...selectedNode.data.menu_options,
        ...data,
      };

      if (!newOptions?.command && state.command)
        newOptions.command = state.command;

      selectedNode.data.menu_options = newOptions;
      selectedNode.data.menuOptions = newOptions;
    },

    [DELETE_XADMIN_NODE](state, id) {
      state.nodes = state.nodes.filter((node) => node.id !== id);
    },

    [SET_XADMIN_DRAGGABLE_NODE](state, data) {
      state.draggableNode = data;
    },

    [RESET_XADMIN_NODES](state, data) {
      state.nodes = [];
    },

    [SET_XADMIN_COMMAND](state, data) {
      state.command = data;
    },

    [SET_XADMIN_SETTINGS](state, data) {
      state.selectedNodeId = data?.data?.nodeId;
      state.selectedSettings = data?.data?.data;
      state.selectedMenuOptions = data?.menuOptions;

      const selectedNode = state.nodes.find((i) => i.id === data?.data?.nodeId);

      if (selectedNode) state.nodeTitle = selectedNode.label;
    },

    [SET_XADMIN_FLOW_POSITION](state, data) {
      state.zoomData = data;
    },

    [SET_XADMIN_FLOW_FIT_VIEW](state, data) {
      state.fitPosition = data;
    },

    [UPDATE_XADMIN_NODE](state, data) {
      const index = state.nodes.findIndex((i) => i.id === data.id);

      const node = state.nodes[index];

      const form = {
        ...data?.value,
        type: "dynamic",
      };

      if (data.key === "output") {
        node.data.outputs.push(form);
      } else {
        node.data.inputs.push(form);
      }
    },

    [UPDATE_XADMIN_NODE_POSITION](state, data) {
      const selectedNode = state.nodes.find((i) => i.id === data.nodeId);

      if (!selectedNode) return;

      selectedNode.position = data.position;
    },

    [DELETE_XADMIN_NODE_INPUT](state, data) {
      const node = state.nodes.find((i) => i.id === data.nodeId);

      if (!node) return;

      // Remove data by index
      if (data.key === "output") {
        node.data.outputs.splice(data.index, 1);
      } else {
        node.data.inputs.splice(data.index, 1);
      }
    },

    [UPDATE_XADMIN_NODES](state, data) {
      state.nodes.push(data);
      state.nodeTitle = data.label;
    },

    [UPDATE_XADMIN_NODE_INPUT](state, data) {
      const selectedNode = state.nodes.find((node) => node.id === data.nodeId);

      if (!selectedNode) return;

      const record = data.value;

      if (data.key === "input") {
        const input = selectedNode.data.inputs[data.index];

        input.label = record.name;
        input.value = record.description;
      } else {
        const output = selectedNode.data.outputs[data.index];

        output.label = record.name;
        output.value = record.description;
      }
    },

    [UPDATE_XADMIN_NODE_TITLE](state, data) {
      const selectedNode = state.nodes.find(
        (node) => node.id === state.selectedNodeId
      );

      const selectedAction = state.flowActions.find(
        (i) => i.id === selectedNode.action_id
      );

      if (data) {
        selectedNode.label = data;
        state.nodeTitle = data;
      } else {
        selectedNode.label = selectedAction?.flowchart?.title;
        state.nodeTitle = selectedAction?.flowchart?.title;
      }
    },

    [UPDATE_XADMIN_EDGES](state, data) {
      state.edges.push(data);
    },

    [UPDATE_XADMIN_QUEUE_OPTION](state, data) {
      const option = state.queue[data.key];

      if (data.key === "queue-general") {
        state.queue[data.key] = data.value;
      } else {
        const index = option.findIndex((item) => item.id === data.value.id);

        if (index !== -1) {
          option[index] = data.value;

          state.queue[data.key] = option;
        }
      }
    },

    [ADD_XADMIN_QUEUE](state, data) {
      state.queue = { ...state.queue, ...data };
    },

    [ADD_XADMIN_QUEUE_OPTION](state, data) {
      state.queue[data.key] = [...state.queue[data.key], data.value];
    },

    [SET_XADMIN_DESTINATIONS](state, data) {
      state.destinations = data;
      state.filteredDestinations = data;
    },

    [UPDATE_XADMIN_DESTINATIONS](state, data) {
      const index = state.destinations.findIndex((item) => item.id === data.id);

      state.destinations[index] = data?.data;
      state.filteredDestinations[index] = data?.data;
    },

    [DELETE_XADMIN_DESTINATIONS](state, data) {
      state.destinations = state.destinations.filter(
        (item) => item.id !== data
      );
      state.filteredDestinations = state.filteredDestinations.filter(
        (item) => item.id !== data
      );
    },

    [ADD_XADMIN_DESTINATIONS](state, data) {
      state.destinations = [...state.destinations, data];
      state.filteredDestinations = [...state.filteredDestinations, data];
    },

    [FILTER_XADMIN_DESTINATIONS](state, letter) {
      if (!letter) {
        state.filteredDestinations = state.destinations;
        return;
      }

      if (letter?.length === 1) {
        state.filteredDestinations = state.destinations.filter((item) =>
          item.prefix_name.toLowerCase().startsWith(letter)
        );
      } else {
        state.filteredDestinations = state.destinations.filter((item) =>
          item.prefix_name.toLowerCase().includes(letter)
        );
      }
    },

    [DELETE_XADMIN_QUEUE_OPTION](state, data) {
      const option = state.queue[data.key];

      const newOptions = option.filter((item) => item.id !== data.value);

      state.queue[data.key] = newOptions;
    },
  },

  actions: {},

  getters: {
    nodes: (state) => state.nodes,
    edges: (state) => state.edges,
  },
};
