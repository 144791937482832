<template>
  <Divider />
  <div class="flex items-center justify-end">
    <Button
      text
      @click="$emit('saveMore')"
      :label="saveAddMoreText"
      v-if="saveAddMoreText"
    />
    <Button text @click="$emit('save')" :label="saveButtonText" />
    <Button text @click="$emit('cancel')" :label="cancelButtonText" />
  </div>
</template>

<script>
import Button from "primevue/button";
import Divider from "primevue/divider";

export default {
  name: "DialogButtons",
  emits: ["save", "cancel", "saveMore"],
  components: {
    Button,
    Divider,
  },

  props: {
    saveButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    saveAddMoreText: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup(props) {
    return {};
  },
};
</script>