<template>
  <div class="relative z-10">
    <div class="text-xl font-inter">
      <div class="form-control">
        <div class="dropdown-with-arrow">
          <input
            type="text"
            name="timezone"
            class="block border-0 border-b-half text-light-gray w-full focus:outline-none dark:bg-gray-800 dark:text-off-white py-1 dark:placeholder-gray-100"
            :class="$attrs.inputClass || ''"
            :placeholder="$t('label.app.common.timezone_select')"
            autocomplete="off"
            @click.stop="open"
            @focus="open"
            @keyup="navigate"
            @keyup.stop.esc="close"
            @keyup.stop.enter="updateAndClose(active)"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
          />
        </div>
      </div>
    </div>

    <dropdown
      :id="dropdownId"
      v-if="isActiveDropdown(dropdownId)"
      classes="top-4 left-0.5"
      style="width: 100%; max-height: 13rem; overflow-y: auto"
    >
      <template v-if="filteredOptions.length">
        <dropdown-item
          classes="dropdown-item bg-white hover:bg-gray-200"
          :class="{ 'active-dropdown': index == 0 }"
          style="padding-top: 5px !important; padding-bottom: 5px !important"
          @click.prevent="updateAndClose(option)"
          v-for="(option, index) in filteredOptions"
          :key="option"
        >
          {{ option }}
        </dropdown-item>
      </template>
      <dropdown-item v-else>{{
        $t("label.app.common.no_match")
      }}</dropdown-item>
    </dropdown>
  </div>
</template>

<script>
export default {
  name: "SelectTimezonesWithSearch",
  inheritAttrs: false,
};
</script>

<script setup>
import { useAttrs, computed, ref } from "vue";
import useActiveToggler from "@/composables/useActiveToggler";
import lodash from "lodash";

const props = defineProps({
  options: {
    type: [Object, Array],
    required: true,
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: null,
  },
  selected: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(["update:modelValue"]);

const active = ref("");

const attrs = useAttrs();

const dropdownId = computed(() => attrs.id || "timezonesDropdown");
const filteredOptions = computed(() => {
  return [...props.options].filter((option) => callback(option));
});

const callback = (option) => {
  const isNotSelected = props.selected != option;
  const search = props.modelValue;
  option = option.replace(/\"/g, "");
  if (search) {
    return (
      option.toLowerCase().indexOf(search.toLowerCase()) != -1 && isNotSelected
    );
  }
  return true;
};

const updateAndClose = (value) => {
  if (value == "" || value == null || value == undefined) return;
  emit("update:modelValue", value);
  toggleDropdown(dropdownId.value);
};

const close = () => {
  if (isActiveDropdown(dropdownId.value)) toggleDropdown(dropdownId.value);
};

const open = () => {
  if (isActiveDropdown(dropdownId.value) == false)
    toggleDropdown(dropdownId.value);
};

const navigate = (e) => {
  if (e.keyCode == 40 || e.keyCode == 38) {
    open();

    const items = document.querySelectorAll(".dropdown-item");
    const item = Object.entries(items).find(([, item]) => {
      return [...item.classList].includes("active-dropdown");
    });

    if (!lodash.isEmpty(item)) {
      const elem = item[1];
      const navigateToElement =
        e.keyCode == 40 ? elem.nextElementSibling : elem.previousElementSibling;
      elem.classList.remove("active-dropdown");

      if (navigateToElement == null) {
        const resetIndex = e.keyCode == 40 ? 0 : items.length - 1;
        items[resetIndex].classList.add("active-dropdown");
        active.value = items[resetIndex].innerText;
      } else {
        navigateToElement.classList.add("active-dropdown");
        active.value = navigateToElement.innerText;
      }
    }
  }
};

const { toggleDropdown, isActiveDropdown } = useActiveToggler();
</script>

<style lang="scss" scoped>
.dropdown::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: -12px;
  right: -1px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.active-dropdown {
  background: rgba(229, 231, 235, 1);
}
</style>
