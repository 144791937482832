<template>
  <div class="field flex justify-start items-start flex-col">
    <label v-if="label">{{ label }}</label>
    <InputSwitch v-model="localIsActive" />
    <QueueHint :title="hint" v-if="hint" />
    <div class="mb-4" v-else></div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import InputSwitch from "primevue/inputswitch";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "SwitchInput",

  components: {
    InputSwitch,
    QueueHint,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const localIsActive = ref(props.modelValue);

    watch(localIsActive, (value) => {
      emit("update:modelValue", value);
    });

    return {
      localIsActive,
    };
  },

  emits: ["update:modelValue"],
};
</script>

