<template>
  <div class="field flex justify-start items-start flex-col">
    <label>{{ label }}</label>
    <div :style="{ width: computedWidth }">
      <Dropdown
        :filter="filter"
        v-model="selectedValue"
        :options="dropdownOptions"
        :optionLabel="optionLabel"
        :placeholder="placeholder"
        class="w-full md:w-14rem"
      />
    </div>
    <small class="p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <div class="mb-4" v-else></div>
</template>

<script>
import { ref, watch, computed, inject } from "vue";
import Dropdown from "primevue/dropdown";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "FormDropdown",

  components: {
    Dropdown,
    QueueHint,
  },

  props: {
    modelValue: {
      required: true,
    },

    options: {
      required: true,
      type: [Array, Object],
    },

    error: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    optionLabel: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    width: {
      type: String,
      default: "",
      required: false,
    },

    filter: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const t = inject("t");
    const selectedValue = ref(props.modelValue);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const dropdownOptions = computed(() => {
      if (props.optionLabel) {
        const defaultOption = {
          [props.optionLabel]: selectText,
          value: null,
        };
        const options = [defaultOption, ...props.options];

        return options;
      }

      return [selectText, ...props.options];
    });

    const computedWidth = computed(() => {
      if (props.width) return props.width;
      // Calculate width based on the longest option
      let longestText = 24;

      if (props?.optionLabel) {
        const longestOption = dropdownOptions.value.reduce((a, b) =>
          a[props.optionLabel]?.length > b[props.optionLabel]?.length ? a : b
        );

        longestText = longestOption[props.optionLabel]?.length || 5;
      } else {
        const mapOptionsToString = dropdownOptions.value.map((option) =>
          option.toString()
        );

        const longestOption = mapOptionsToString.reduce((a, b) =>
          a.length > b.length ? a : b
        );

        longestText = longestOption?.length;
      }

      if (longestText < 6) longestText = 10;
      if (longestText > 30) longestText = 30;

      longestText = longestText * 10;

      return `${longestText}px`;
    });

    watch(selectedValue, (value) => {
      let emitedValue = value?.value === null ? null : value?.value || value;
      console.log(emitedValue);
      if (emitedValue === selectText) emitedValue = null;
      emit("update:modelValue", emitedValue);
    });

    return {
      selectedValue,
      computedWidth,
      dropdownOptions,
    };
  },
};
</script>

<style>
</style>