<template>
  <div class="bg-white w-full">
    <section class="flex flex-row flex-wrap w-full">
      <div class="w-full">
        <div class="flex flex-col justify-center items-center mb-10 mx-auto">
          <div class="mb-1 font-bold text-3xl">
            <p>
              {{
                $t("label.profile.profile-subscriptions.subscriptions-heading")
              }}
            </p>
          </div>
        </div>
      </div>

      <!-- Subscriptions table -->
      <div class="w-full mx-auto" v-if="subscriptions && subscriptions?.length">
        <table
          class="w-full border-2 collapse border-solid m-0 b-0 table-fixed"
        >
          <thead class="">
            <tr
              class="text-app-purple text-lg border-b-2 border-purple-400 border-solid text-left"
            >
              <th scope="col" class="py-3 pl-2">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.widget-name"
                  )
                }}
              </th>
              <th scope="col" class="pl-2">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.subscriptions"
                  )
                }}
              </th>
              <th scope="col" class="pr-2 text-right">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.price"
                  )
                }}
              </th>
              <th scope="col font-bold" class="pl-2">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.builing-preiod"
                  )
                }}
              </th>
              <th scope="col font-bold" class="pl-2">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.expire_at"
                  )
                }}
              </th>
              <th scope="col" class="pl-2">
                {{
                  $t(
                    "label.profile.profile-subscriptions.subscriptions-table.subscription"
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b-2 border-dotted text-left"
              v-for="(item, index) in subscriptions"
              :key="index"
            >
              <td data-label="Widget name" class="pl-2 text-left">
                {{ item.widget_name }}
              </td>
              <td data-label="Name" class="capitalize text-left">
                {{ item.subscription }} {{ item.name }}
              </td>
              <td data-label="Price" class="text-right pr-2">
                {{ item.price }}
              </td>
              <td data-label="Billing period" class="pl-2">
                {{ item.billing_period }}
              </td>
              <td data-label="Expire at" class="pl-2">
                {{ item.expire_at }}
              </td>
              <td data-label="Date" class="pl-2">
                <p
                  @click="subscribe(item.id)"
                  v-if="!item.stripe_subscription_id"
                  class="cursor-pointer subscribe-btn"
                >
                  {{
                    $t(
                      "label.profile.profile-subscriptions.subscriptions-table.subscribe"
                    )
                  }}
                </p>
                <p
                  v-else
                  class="cursor-pointer subscribe-btn unscuscribe-btn"
                  @click="unsuscribe(item.id)"
                >
                  {{
                    $t(
                      "label.profile.profile-subscriptions.subscriptions-table.unsubscribe"
                    )
                  }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-full" v-else>
        <p class="text-3xl text-center my-5">
          {{ $t("label.profile.profile-payment.body.transactions.no-result") }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import api from "@/services/api";

export default {
  setup() {
    const store = useStore();

    const subscriptions = computed(
      () => store.state.profilestore.user_payments_methods
    );

    const subscribe = async (id) => {
      try {
        const response = await api.get(`/stripe/subscribe/${id}`);

        if (response?.status === 200 && response?.data)
          window.location.href = response.data;
      } catch (error) {}
    };

    const getProfileSubscriptions = () => {
      store.dispatch("profilestore/getProfileSubscriptions");
    };

    const unsuscribe = async (id) => {
      // window.location.href = `${process.env.API_URL}/stripe/subscribe/${id}`
      try {
        const response = await api.delete(`/stripe/subscribe/${id}`);

        if (response?.status === 200 && response?.data)
          window.location.href = response?.data;
      } catch (error) {}
    };

    onMounted(() => {
      getProfileSubscriptions();
    });

    return {
      subscribe,
      unsuscribe,
      subscriptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.subscribe-btn {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 5px 12px;
  background-color: rgb(26, 179, 148);
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin: 5px 0;
  min-width: 120px;
}

.unscuscribe-btn {
  background-color: #333;
  opacity: 0.7;
}
</style>
