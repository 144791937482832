<template>
  <div class="field flex flex-col justify-start">
    <label class="text-left" :for="id">{{ label }}</label>
    <InputText
      :id="id"
      autofocus
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <small class="text-left p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <div class="mb-4" v-else></div>
</template>

<script>
import InputText from "primevue/inputtext";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "FormInput",

  components: {
    InputText,
    QueueHint,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    modelValue: {
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },
  },
};
</script>