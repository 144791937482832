/* eslint-disable */
const routes = [
  {
    path: "/billing",
    name: "Billing",
    component: () => import("@/modules/billing/pages/billing"),
    meta: {
      requiresAuth: true,
      verified: true,
    },
    children: [],
  },
  {
    meta: {
      requiresAuth: true,
      verified: true,
    },
  },
];
export default routes;
