export const userHeaders = [
  {
    label: "Status",
    field: "is_active",
  },
  {
    label: "User",
    field: "username",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Extension",
    field: "extension",
  },
  {
    label: "Timezone",
    field: "timezone",
  },
  {
    label: "Created At",
    field: "created_at",
  },
  {
    label: "Updated At",
    field: "updated_at",
  },
];

export const contactHeaders = [
  {
    label: "First name",
    field: "first_name",
  },
  {
    label: "Last name",
    field: "last_name",
  },
  {
    label: "Phone number",
    field: "number",
  },
  {
    label: "Company",
    field: "company",
  },
  {
    label: "Email",
    field: "email",
  },
];

export const temlateHeaders = [
  {
    label: "Status",
    field: "is_active",
  },
  {
    label: "Name",
    field: "description",
  },
  {
    label: "Content",
    field: "message",
  },
  {
    label: "Channel",
    field: "channel",
  },
];

export const domainHeaders = [
  {
    label: "Domain",
    field: "domain",
  },
  {
    label: "Created At",
    field: "created_at",
  },
  {
    label: "Updated At",
    field: "updated_at",
  },
];

export const SMSReportsHeaders = [
  {
    label: "Direction",
    field: "msgtype",
  },
  {
    label: "Dialing number",
    field: "calling_number",
  },
  {
    label: "Dialed number",
    field: "called_number",
  },
  {
    label: "Date",
    field: "created_at",
  },
  {
    label: "Result",
    field: "msgstatus",
  },
  {
    label: "Amount",
    field: "client_cost",
  },
];

export const CallsReportsHeaders = [
  {
    label: "Direction",
    field: "calltype",
  },
  {
    label: "Calling number",
    field: "calling_number",
  },
  {
    label: "Called number",
    field: "called_number",
  },
  {
    label: "Start",
    field: "callstart",
  },
  {
    label: "End",
    field: "callstop",
  },
  {
    label: "Result from call",
    field: "dialstatus",
  },
  {
    label: "Duration",
    field: "duration",
  },
  {
    label: "Amount",
    field: "client_cost",
  },
];

export const didsTable = [
  {
    label: "Number",
    field: "number",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Country",
    field: "country",
  },
  {
    label: "Number type",
    field: "numberType",
  },
  {
    label: "Created at",
    field: "created_at",
  },
  {
    label: "Updated at",
    field: "updated_at",
  },
];

export const extensionHeaders = [
  {
    label: "Extension",
    field: "extension",
  },
  {
    label: "User",
    field: "user",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Status",
    field: "is_active",
  },
  {
    label: "Created at",
    field: "created_at",
  },
  {
    label: "Updated at",
    field: "updated_at",
  },
];

export const sendersHeaders = [
  {
    label: "Country",
    field: "country",
  },
  {
    label: "Status",
    field: "order_status",
  },
  {
    label: "Channel",
    field: "channel",
  },
  {
    label: "Created at",
    field: "created_at",
  },
];

export const timezonesHeaders = [
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Status",
    field: "is_active",
  },
  {
    label: "Priority",
    field: "priority",
  },
];

export const soundFilesHeaders = [
  {
    label: "Name",
    field: "filename",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Size",
    field: "filesize",
  },
  {
    label: "Created at",
    field: "created_at",
  },
];

export const queuesHeaders = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Queue number",
    field: "queue_number",
  },
  {
    label: "Weight",
    field: "weight",
  },
  {
    label: "Wrapup time",
    field: "wrapuptime",
  },
  {
    label: "Timeout",
    field: "timeout",
  },
  {
    label: "Strategy",
    field: "strategy",
  },
  {
    label: "Unblock",
    field: "unblock",
  },
  {
    label: "Created at",
    field: "create_time",
  },
  {
    label: "Updated at",
    field: "update_time",
  },
];

export const voicemailsHeaders = [
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Mail subject",
    field: "mail_subject",
  },
  {
    label: "Mail body",
    field: "mail_body",
  },
  {
    label: "Mail from",
    field: "mail_from",
  },
  {
    label: "Mail to",
    field: "mail_to",
  },
  {
    label: "Mail signature",
    field: "mail_signature",
  },
  {
    label: "Mail cc",
    field: "mail_cc",
  },
  {
    label: "Mail bcc",
    field: "mail_bcc",
  },
  {
    label: "Created at",
    field: "created_at",
  },
  {
    label: "Updated at",
    field: "updated_at",
  },
];

export const carriersHeaders = [
  {
    label: "Carrier name",
    field: "carrier_name",
  },
  {
    label: "Timezone",
    field: "timezone",
  },
  {
    label: "Is active",
    field: "is_active",
  },
];

export const inboundHeaders = [
  {
    label: "A regexp",
    field: "a_regexp",
  },
  {
    label: "B regexp",
    field: "b_regexp",
  },
  {
    label: "FLow",
    field: "flow_id",
  },
  {
    label: "Priority",
    field: "priority",
  },
  {
    label: "Timeslot",
    field: "timeslot_id",
  },
  {
    label: "Application",
    field: "application_id",
  },
];
