export const SET_LOADING = 'setLoading'
export const SET_MOUNTED = 'setMounted'
export const SET_WIDGETS = 'setWidgets'
export const SET_WIDGET = 'setWidget'
export const GET_WIDGETS = 'getWidgets'
export const EDIT_WIDGET = 'editWidget'
export const SAVE_CONTACT  = 'saveContact'
export const SAVE_PHONE  = 'savePhone'
export const INSTALL_PHONE  = 'installPhone'
export const RESET_PHONE = 'resetPhone'
export const EDIT_PHONE = 'editPhone'
export const DELETE_PHONE = 'deletePhone'
export const SET_ACTIVE_WIDGET = 'setActiveWidget'
export const EDIT_CONTACT = 'editContact'
export const SET_ACTIVE_RESOURCE = 'setActiveResource'
export const DELETE_CONTACT = 'deleteContact'
export const SAVE_CONTACTS = 'saveContacts'
export const SAVE_DEPARTMENT = 'saveDepartment'
export const DELETE_DEPARTMENT = 'deleteDepartment'
export const EDIT_DEPARTMENT = 'editDepartment'
export const RESET_RESOURCE = 'resetResource'
export const SET_LANG = 'setLang'
export const SAVE_USER_LANGUAGE = 'saveUserLanguage'
export const SAVE_USER_EDITED_LANGS = 'saveUserEditedLangs'
export const DELETE_LANG = 'deleteLang'
export const SAVE_MULTILANG = 'saveMultiLang'
export const SAVE_DEFAULT_LANG = 'saveDefaultLang'
export const SAVE_SIDEBAR = 'saveSidebar'
export const SAVE_APPEARANCE = 'saveAppearance'
export const TOGGLE_WIDGET = 'toggleWidget'
export const RESET_COLOR_SCHEME = 'resetColorScheme'
export const RESET_APPEARANCE_COLOR_SCHEME = 'resetAppearanceColorScheme'
export const SET_APPEARANCE_RESOURCE = 'setAppearanceResource'
export const GET_TARIFFS = 'getTariffs'
export const SET_TARIFFS = 'setTariffs'
export const SAVE_COUNTRIES = 'saveCountries'
export const SAVE_BLACKLIST_IPS = 'saveBlacklistIps'
export const GET_STATS = 'getStats'
export const SET_STATS = 'setStats'
export const GET_MARKERS = 'getMarkers'
export const SET_MARKERS = 'setMarkers'
export const GET_STATS_FILTERS = 'GET_STATS_FILTERS'
export const SAVE_STATS_FILTERS = 'SAVE_STATS_FILTERS'
export const GET_TARIFF_TYPES = 'GET_TARIFF_TYPES'
export const SAVE_TARIFF_TYPES = 'SAVE_TARIFF_TYPES'
export const SET_XADMIN_HEADER = 'SET_XADMIN_HEADER'
export const SET_XADMIN_QUEUE = 'SET_XADMIN_QUEUE'
export const ADD_XADMIN_QUEUE = 'ADD_XADMIN_QUEUE'
export const ADD_XADMIN_QUEUE_OPTION = 'ADD_XADMIN_QUEUE_OPTION'
export const UPDATE_XADMIN_QUEUE_OPTION = 'UPDATE_XADMIN_QUEUE_OPTION'
export const DELETE_XADMIN_QUEUE_OPTION = 'DELETE_XADMIN_QUEUE_OPTION'
export const SET_XADMIN_GLOBAL_LOADER = 'SET_XADMIN_GLOBAL_LOADER'
export const SET_XADMIN_NODES = 'SET_XADMIN_NODES'
export const SET_XADMIN_EDGES = 'SET_XADMIN_EDGES'
export const UPDATE_XADMIN_EDGES = 'UPDATE_XADMIN_EDGES'
export const UPDATE_XADMIN_NODES = 'UPDATE_XADMIN_NODES'
export const UPDATE_XADMIN_NODE = 'UPDATE_XADMIN_NODE'
export const DELETE_XADMIN_NODE = 'DELETE_XADMIN_NODE'
export const RESET_XADMIN_NODES = 'RESET_XADMIN_NODES'
export const SET_XADMIN_VERSION = 'SET_XADMIN_VERSION'
export const SET_XADMIN_SETTINGS = 'SET_XADMIN_SETTINGS'
export const SET_XADMIN_ACTIONS = 'SET_XADMIN_ACTIONS'
export const SET_XADMIN_COMMAND = 'SET_XADMIN_COMMAND'
export const UPDATE_XADMIN_NODE_TITLE = 'UPDATE_XADMIN_NODE_TITLE'
export const SET_XADMIN_DRAGGABLE_NODE = 'SET_XADMIN_DRAGGABLE_NODE'
export const SET_XADMIN_FLOW_POSITION = 'SET_XADMIN_FLOW_POSITION'
export const UPDATE_XADMIN_NODE_POSITION = 'UPDATE_XADMIN_NODE_POSITION'
export const SET_XADMIN_FLOW_FIT_VIEW = 'SET_XADMIN_FLOW_FIT_VIEW'
export const UPDATE_XADMIN_NODE_INPUT = 'UPDATE_XADMIN_NODE_INPUT'
export const DELETE_XADMIN_NODE_INPUT = 'DELETE_XADMIN_NODE_INPUT'
export const SET_XADMIN_NODE_MENU_OPTIONS = 'SET_XADMIN_NODE_MENU_OPTIONS'
export const UPDATE_XADMIN_SETTINGS_VERSION = 'UPDATE_XADMIN_SETTINGS_VERSION'
export const SET_XADMIN_DESTINATIONS = 'SET_XADMIN_DESTINATIONS'
export const ADD_XADMIN_DESTINATIONS = 'ADD_XADMIN_DESTINATIONS'
export const UPDATE_XADMIN_DESTINATIONS = 'UPDATE_XADMIN_DESTINATIONS'
export const DELETE_XADMIN_DESTINATIONS = 'DELETE_XADMIN_DESTINATIONS'
export const FILTER_XADMIN_DESTINATIONS = 'FILTER_XADMIN_DESTINATIONS'
