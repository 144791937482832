/* eslint-disable */

import { createApp } from "vue";
import moment from "moment";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import i18nSetup from "./services/i18nSetup";
import VueClickAway from "vue3-click-away";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import useToaster from "@/composables/useToaster";
import PrimeVue from "primevue/config";
import VueExcelXlsx from "vue-excel-xlsx";
import VueFlow from '@vue-flow/core';
import '@vue-flow/core/dist/style.css';
import ConfirmationService from 'primevue/confirmationservice';

import "vue-color-kit/dist/vue-color-kit.css";
import "@/assets/css/primevue.css";
import "@/assets/css/index.css";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import "@vue-flow/core/dist/style.css";
import '@vue-flow/core/dist/theme-default.css';

import globalComponents from "./services/global-components";
import authenticate from "@/composables/auth/authenticate";

// import 'primevue/resources/themes/aura-light-green/theme.css'
import "primevue/resources/primevue.min.css";

const { trigger, triggerAlert } = useToaster();
const auth = authenticate();
const { i18n } = i18nSetup();

const app = createApp(App);
globalComponents(app);
app.use(VueFlow);
app.use(i18n);
app.use(store);
app.use(VueClickAway);
app.use(VueExcelXlsx);
app.use(ConfirmationService);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.API_KEY,
    language: "en",
  },
});
// app.use(PrimeVue);

app.use(PrimeVue, {
  styled: false,
  // unstyled: false,
});

app.config.globalProperties.$moment = moment;
app.provide("moment", moment);
app.provide("notify", trigger);
app.provide("triggerAlert", triggerAlert);
app.provide("t", i18n.global.t);

auth
  .getProfile(null)
  // .getProfile(sessionStorage.getItem("token"))
  .then(() => {
    app.use(router);
    app.mount("#app");
  })
  .catch(() => {
    app.use(router);
    app.mount("#app");
  });
