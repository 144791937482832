<template>
  <UsersTable :showLogin="true" />
</template>

<script>
import UsersTable from "@/modules/xpbx/components/users/UsersTable/UsersTable.vue";

export default {
  name: "ProfileUsers",

  components: {
    UsersTable,
  },
};
</script>